.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 50px;
    background-color: #12141d;
    color: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }
  
  .header .logo {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  
  .header .logo img {
    height: 40px;
  }
  
  .header .logo h2 {
    font-size: 1.8em;
    margin-left: 10px;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    color: white;
  }
  
  .header nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .header nav ul li {
    margin: 0 20px;
  }
  
  .header nav ul li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Arial', sans-serif;
    cursor: pointer;
  }
  
  .auth-buttons {
    display: flex;
  }
  
  .auth-buttons .login {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
  }
  
  .auth-buttons .signup {
    background-color: #155eef;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
  }
  
  .auth-buttons .login:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .auth-buttons .signup:hover {
    background-color: #0d4bcf;
  }
  
  @media (max-width: 1050px) {
    .header
    .logo img {
      height: 35px;
    }

    .header
    .logo h2 {
      font-size: 1.4em;
      margin-left: 10px;
      font-family: 'Arial', sans-serif;
      font-weight: bold;
      color: white;
    }
  
    .header {
      justify-content: center;
    }

    .header nav ul li {
      margin: 0 20px;
    }
    
    .header nav ul li a {
      color: white;
      text-decoration: none;
      font-weight: bold;
      font-family: 'Arial', sans-serif;
      font-size: 14px;
    }

    .auth-buttons {
      display: none;
    }
  }


  @media (max-width: 768px) {
    .header nav,
    .auth-buttons {
      display: none;
    }
  
    .header {
      justify-content: center;
    }

    .header
    .logo img {
      height: 45px;
    }

    .header
    .logo h2 {
      font-size: 2em;
      margin-left: 10px;
      font-family: 'Arial', sans-serif;
      font-weight: bold;
      color: white;
    }
  }
  