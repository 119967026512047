.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 80px 50px;
  background-color: #12141d;
  color: white;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}

.content {
  text-align: left;
}

.content h1 {
  font-size: 3.5em;
  margin-bottom: 10px;
}

.content p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.hero-section .content .download-button {
  background-color: #155eef;
  color: white;
  padding: 15px 20px;
  font-size: 1.2em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.hero-section .content .content-stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.hero-section .content .content-stats .customers {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.hero-section .content .content-stats .customers img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-right: 10px;
}

.hero-section .content .content-stats .customers .customers-stats h3 {
  font-size: 2em;
  margin-bottom: -20px;
}
.hero-section .content .content-stats .customers .customers-stats p{
  font-size: 1.1em;
}

.hero-section .content .content-stats .rating {
  display: flex;
  flex-direction: column;
}

.hero-section .content .content-stats .rating .rating-bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.hero-section .content .content-stats .rating h3 {
  font-size: 2em;
  margin-bottom: -20px;
}

.hero-section .content .content-stats .rating .rating-bottom p{
  font-size: 1.1em;
}

.hero-section .content .content-stats .rating .rating-bottom .rating-stars {
  color: gold;
  margin-right: 10px;
  margin-top: -10px;
}

.hero-image {
  max-width: 40%;
}

.hero-image img {
  width: 100%;
  height: auto;
}

@media (max-width: 1700px) {

  .hero-image {
    max-width: 40%;
  }
  
  .hero-image img {
    width: 100%;
    height: auto;
  }
}


@media (max-width: 1200px) {
  .hero-section .content h1{
    font-size: 3em;
  }

  .hero-section .content p{
    font-size: 1em;
  }
  .hero-section .content .download-button {
    font-size: 1em;
  }

  .hero-image {
    max-width: 40%;
  }
  
  .hero-image img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 1000px) {
  .hero-section .content h1{
    font-size: 2.5em;
  }

  .hero-section .content p{
    font-size: 1em;
  }
  .hero-section .content .download-button {
    font-size: 1em;
  }

  .hero-section .content .content-stats .customers img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .hero-section .content .content-stats .customers .customers-stats h3 {
    font-size: 1.4em;
    margin-bottom: -20px;
  }
  .hero-section .content .content-stats .customers .customers-stats p{
    font-size: 1em;
  }

  .hero-section .content .content-stats .rating h3 {
    font-size: 1.4em;
    margin-bottom: -20px;
  }
  
  .hero-section .content .content-stats .rating .rating-bottom p{
    font-size: 1em;
  }
  
  .hero-section .content .content-stats .rating .rating-bottom .rating-stars {
    color: gold;
    margin-right: 10px;
    margin-top: -10px;
  }
  
  .hero-image {
    max-width: 40%;
  }
  
  .hero-image img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 830px) {

  .hero-section .content h1{
    font-size: 2.2em;
  }

  .hero-section .content p{
    font-size: 1em;
  }
  .hero-section .content .download-button {
    font-size: 0.8em;
    font-weight: bold;
  }

  .hero-section .content .content-stats .customers img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .hero-section .content .content-stats .customers .customers-stats h3 {
    font-size: 1.2em;
    margin-bottom: -10px;
  }
  .hero-section .content .content-stats .customers .customers-stats p{
    font-size: 0.8em;
  }

  .hero-section .content .content-stats .rating h3 {
    font-size: 1.2em;
    margin-bottom: -22px;
  }
  
  .hero-section .content .content-stats .rating .rating-bottom p{
    font-size: 0.8em;
    margin-top: 25px;
  }
  
  .hero-section .content .content-stats .rating .rating-bottom .rating-stars .icon{
    height: 10px;
  }
}

@media (max-width: 680px) {
  .hero-section{
    flex-direction: column;
  }

  .hero-image {
    max-width: 70%;
  }
  
  .hero-image img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 470px) {

  .hero-section .content .content-stats .customers img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .hero-section .content .content-stats .customers .customers-stats h3 {
    font-size: 1em;
    margin-bottom: -10px;
  }
  .hero-section .content .content-stats .customers .customers-stats p{
    font-size: 0.6em;
  }

  .hero-section .content .content-stats .rating h3 {
    font-size: 1em;
    margin-bottom: -28px;
  }
  
  .hero-section .content .content-stats .rating .rating-bottom p{
    font-size: 0.6em;
    margin-top: 25px;
  }
  
  .hero-section .content .content-stats .rating .rating-bottom .rating-stars .icon{
    height: 8px;
  }

}

@media (max-width: 405px) {
  .hero-section {
    flex-direction: column;
  }

  .hero-section .content .content-stats .rating .rating-bottom .rating-stars .icon{
    height: 6px;
  }
  
}

@media (max-width: 380px) {

  .hero-section .content .content-stats .customers img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .hero-section .content .content-stats .customers .customers-stats h3 {
    font-size: 1.2em;
    margin-bottom: -10px;
  }
  .hero-section .content .content-stats .customers .customers-stats p{
    font-size: 0.7em;
  }

  .hero-section .content .content-stats{
    display: none;
  }

  .hero-image {
    display: none;
  }
  
}