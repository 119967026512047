.testimonials-section {
  background-color: white;
  padding: 50px 20px;
  text-align: center;
  color: #333;
  border-radius: 20px;
}

.testimonials-header {
  margin-bottom: 40px;
}

.testimonials-header h2 {
  font-size: 2.5em;
  color: #12141d;
  margin-bottom: 10px;
}

.testimonials-header p {
  font-size: 1.2em;
  color: #666;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: minmax(100px, auto);
  gap: 20px;
}

.testimonial-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-0, .card-2, .card-4, .card-6 {
  grid-row: span 1;
  height: auto;
}

.card-1, .card-3, .card-5, .card-7 {
  grid-row: span 2;
  height: 450px; /* Adjust this value to control the height of the larger cards */
}

.stars {
  color: #ffd700;
  margin-bottom: 10px;
}

.quote {
  font-size: 1em;
  margin-bottom: 20px;
  color: #333;
}

.author {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.author .name {
  font-weight: bold;
  color: #333;
}

.author .role {
  font-size: 0.9em;
  color: #666;
}
