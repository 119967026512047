.download-section {
  background-color: #12141d;
  padding: 20px 20px;
  text-align: left;
  color: white;
  border-radius: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 100px;
}

.download-section h2 {
  font-size: 3.5em;
  margin-bottom: 10px;
}

.download-section p {
  font-size: 1.1em;
  margin-bottom: 20px;
}

.download-buttons {
  display: flex;
  gap: 20px;
}

.download-buttons a {
  padding: 20px 40px;
  font-size: 1.1em;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  border: 2px solid white;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.download-buttons .app-store-button {
  background-color: transparent;
  border-color: white;
}

.download-buttons .google-play-button {
  background-color: white;
  color: #12141d;
}

.download-buttons .app-store-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.download-buttons .google-play-button:hover {
  background-color: #e6e6e6;
}


@media (max-width: 1250px) {

  .download-section h2 {
    font-size: 2.8em;
  }

  .download-section p {
    font-size: 1em;
  }

  .download-buttons a {
    padding: 20px 40px;
    font-size: 1em;
    font-weight: bold;
    text-decoration: none;
    border-radius: 5px;
    border: 2px solid white;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
   
 }

 @media (max-width: 1050px) {

  .download-section {
    flex-direction: column;
    padding: 20px 20px;
    text-align: center;
    color: white;
    border-radius: 40px;
    align-items: center;
  }

  .download-buttons a {
    padding: 15px 30px;
    font-size: 0.8em;
    font-weight: bold;
    text-decoration: none;
    border-radius: 5px;
    border: 2px solid white;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
 }

 @media (max-width: 750px) {

  .download-section h2 {
    font-size: 2.5em;
  }

  .download-section p {
    font-size: 0.9em;
  }

  .download-section {
    flex-direction: column;
    padding: 20px 20px;
    text-align: center;
    color: white;
    border-radius: 40px;
    align-items: center;
  }

  .download-buttons a {
    padding: 10px 25px;
    font-size: 0.7em;
    font-weight: bold;
    text-decoration: none;
    border-radius: 5px;
    border: 2px solid white;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
 }