.footer {
    background-color: #12141d;
    color: white;
    padding: 50px 50px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  
  /* .book-demo, .quick-links {
    width: 30%;
  }

  .get-in-touch {
    width: 30%;
    align-items: center;
  } */
  
  .book-demo h2, .quick-links h2, .get-in-touch h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .book-demo p, .quick-links ul, .get-in-touch p {
    font-size: 1em;
    margin-bottom: 20px;
  }

  .book-demo {
    width: 50%;
  }
  
  .book-demo form {
    display: flex;
    flex-direction: column;
  }
  
  .book-demo .input-group {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    
  }

  .book-demo .input-group input{
    width: 100%;
  }
  
  .book-demo input, .book-demo textarea {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #1f1f1f;
    color: white;
  }
  
  .book-demo button {
    padding: 15px 20px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .book-demo button:hover {
    background-color: #555;
  }
  
  .quick-links ul {
    list-style: none;
    padding: 0;
  }
  
  .quick-links ul li {
    margin-bottom: 10px;
  }
  
  .quick-links ul li a {
    color: white;
    text-decoration: none;
  }
  
  .quick-links ul li a:hover {
    text-decoration: underline;
  }
  
  .social-media a {
    color: white;
    margin-right: 10px;
    font-size: 1.5em;
  }
  
  .get-in-touch p a {
    color: white;
    text-decoration: none;
  }
  
  .get-in-touch p a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    text-align: center;
    border-top: 1px solid #333;
    padding-top: 20px;
    font-size: 0.9em;
  }
  

  @media (max-width: 940px) {

    .footer-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      margin-bottom: 30px;
    }
    .footer-content .book-demo {
      width: 90%;
      margin-bottom: 50px;
    }

    .footer-content .quick-links{
      width: 90%;
      margin-bottom: 50px;
    }
     
   }
   