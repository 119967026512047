.feature-section {
  background-color: #f8f9fa;
  padding: 50px 50px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.feature-section h2 {
  font-size: 2.5em;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
}

.feature-list {
  display: flex;
  justify-content: space-around;
}

.feature {
  display: flex;
  align-items: center;
  width: 25%;
  text-align: left;
}

.icon-circle1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fcd7d7;
  margin-right: 20px;
  font-size: 1.5em;
  color: #333;
}

.icon-circle2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #d4e3f9;
  margin-right: 20px;
  font-size: 1.5em;
  color: #333;
}

.icon-circle3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #dbeddc;
  margin-right: 20px;
  font-size: 1.5em;
  color: #333;
}

.feature-text {
  flex: 1;
}

.feature h3 {
  color: #333;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.feature p {
  color: #666;
  font-size: 1em;
}


@media (max-width: 800px) {

 .feature-list {
    flex-direction: column;
    align-items: center;
  }

  .feature-list .feature{
    flex: 1;
    width: 100%;
  }
  
}