.App {
  text-align: center;
  padding: 20px;
}

@media (max-width: 550px) {

  .App {
     padding: 10px;
  }
   
 }
